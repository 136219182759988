<template>
  <div class="main-container">
    <div class="header-with-button">
      <h1 class="main-heading">{{$t('EVENT')}}</h1>
      <Button v-if="$route.query.id" type="success" :onClick="openConfirm" text="Submit" :loading="false" size="md" />
      <Button v-else type="success" :onClick="submit" text="Submit" :loading="false" size="md" />
    </div>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <select v-model="selectedLanguage">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`NAME`) }}</p>
            <label class="field-info">*Will be displayed in leaderboard reward mail</label>
            <input v-model="currentEvent.name" />
          </div>
          <div class="form-container">
            <p>{{ "Localized Name" }}</p>
            <input v-model="currentEvent.localizables.name[selectedLanguage]" />
          </div>

          <div class="form-container">
            <p>{{ $t(`Short Description`) }}</p>
            <input v-model="currentEvent.short_description" />
          </div>
          <div class="form-container">
            <p>{{ "Localized Short Description" }}</p>
            <input v-model="currentEvent.localizables.short_description[selectedLanguage]" />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.TYPE`) }}</p>
            <select v-model.number="currentEvent.type">
              <option v-for="type in eventConstants.EVENT_TYPES" v-bind:key="type.value" :value="type.value" :disabled="type.value === 0">{{ $t(type.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ $t(`EVENT_VALUES.MODIFIER_TYPE`) }}</p>
            <select v-model.number="currentEvent.modifier_type">
              <option v-for="type in eventConstants.GAME_MODE_MODIFIER_TYPES" v-bind:key="type.value" :value="type.value">{{ $t('EVENT_VALUES.'+type.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ "Arena Mode" }}</p>
            <select v-model.number="currentEvent.arena">
              <option v-for="type in eventConstants.ARENA_OPTIONS" v-bind:key="type.value" :value="type.value">{{ type.text }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ $t(`EVENT_VALUES.CARD_LEVEL`) }}</p>
            <input type="number" v-model.number="currentEvent.card_level" min="1" />
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ "Ticket Price" }}</p>
            <input type="number" v-model.number="currentEvent.ticket_gem_value" min="1" />
          </div>
          <div class="form-container">
            <p> Critical Damage </p>
            <input v-model="currentEvent.critical_damage" />
          </div>
          <div class="form-container">
            <p> Critical Rate </p>
            <input v-model="currentEvent.critical_rate" />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.START_DATE`) }}</p>
            <DatePicker
                v-model.number="currentEvent.start_date"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="start_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.END_DATE`) }}</p>
            <DatePicker
                v-model.number="currentEvent.end_date"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="end_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.EVENT_PAGE_ASSET`) }}</p>
            <multiselect
              v-model="currentEvent.event_page_asset"
              :options="assetOptions"
              :multiple="false"
            />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.INSIDE_ASSET`) }}</p>
            <multiselect
              v-model="currentEvent.inside_asset"
              :options="assetOptions"
              :multiple="false"
            />
          </div>
        </div>
        <div class="col">
          <div class="header-with-button">
            <div class="col-title">{{ "Info Fields" }}</div>
            <div class="flex">
              <Button :loading="false" text="Get Defaults" size="sm" :onClick="() => getDefaultInfoFields()" type="info" />
              <Button :loading="false" text="Add" size="sm" :onClick="() => addInfoField()" type="success" />
              <Button :loading="false" text="Remove" size="sm" :onClick="() => removeInfoField(currentEvent.info_fields.length-1)" type="error" />
            </div>
          </div>
          <div v-for="(field, index) in currentEvent.info_fields" v-bind:key="index" class="info-field-container">
            <div class="form-container">
              <p>Icon</p>
              <select v-model.number="field.icon">
                <option v-for="type in eventConstants.INFO_FIELD_ICONS" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
              </select>
            </div>
            <div class="form-container">
                <p>{{ "Field Title" }}</p>
                <input v-model="field.name[selectedLanguage]" />
            </div>
            <div class="form-container">
              <p>{{ "Text" }}</p>
              <textarea v-model="field.text[selectedLanguage]" />
            </div>
          </div>
          <push-noification-schedule :notifications="currentEvent.push_notifications" :setNotifications="(data) => { currentEvent.push_notifications = data }" />
          <div class="col-title">{{ $t(`EVENT_VALUES.WIN_REWARDS`) }}</div>
          <div v-for="index in Object.keys(currentEvent.rewards)" v-bind:key="index" class="reward-cell">
            <select v-model.number="currentEvent.rewards[index][0].type" @change="() => updateRewardBody(index)">
              <option v-for="type in eventConstants.EVENT_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,3].includes(currentEvent.rewards[index][0].type)" v-model.number="currentEvent.rewards[index][0].amount" placeholder="Amount" />
            <input v-if="[6].includes(currentEvent.rewards[index][0].type)" v-model.number="currentEvent.rewards[index][0].emoji_index" placeholder="Emoji Index" />
            <input v-if="[14].includes(currentEvent.rewards[index][0].type)" v-model.number="currentEvent.rewards[index][0].avatar_index" placeholder="Avatar Index" />
            <select v-if="currentEvent.rewards[index][0].type === 3" v-model.number="currentEvent.rewards[index][0].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="currentEvent.rewards[index][0].type === 2" v-model="currentEvent.rewards[index][0].id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
          </div>
          <div class="flex margin">
            <Button type="error" :onClick="() => removeLastReward()" text="Remove Reward" :loading="false" size="md" />
            <Button type="success" :onClick="() => addReward()" text="Add Reward" :loading="false" size="md" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-title">{{ $t(`EVENT_VALUES.LEADERBOARD_REWARDS`) }}</div>
        <div v-for="(leaderboardReward, index) in currentEvent.leaderboard_rewards" v-bind:key="index" class="cell leaderboard-cell" :class="index % 2 === 1 ? 'alternate' : ''">
          <div class="leaderboard-container">
            <span>{{ $t("EVENT_VALUES.RANKING") }}</span>
            <div>{{ currentEvent.leaderboard_rewards.slice(0, index).reduce((acc, val) => acc + val.rewardCount, 0) + 1  + ( leaderboardReward.rewardCount > 1 ? "-" +  (currentEvent.leaderboard_rewards.slice(0, index).reduce((acc, val) => acc+ val.rewardCount, 0) + leaderboardReward.rewardCount): "") }}</div>
          </div>
          <div class="leaderboard-container">
            <span>{{ $t("EVENT_VALUES.REWARD_COUNT") }}</span>
            <input v-model.number="leaderboardReward.rewardCount" min="1" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[0].type" @change="() => updateLeaderboardRewardBody(index, 0)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[0].type)" v-model.number="leaderboardReward.content[0].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[0].type === 3" v-model.number="leaderboardReward.content[0].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[0].type === 2" v-model="leaderboardReward.content[0].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[0].type === 6" v-model.number="leaderboardReward.content[0].emoji_index" min="1" placeholder="Emoji Index" />
            <input v-if="leaderboardReward.content[0].type === 14" v-model.number="leaderboardReward.content[0].avatar_index" min="1" placeholder="Avatar Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[1].type" @change="() => updateLeaderboardRewardBody(index, 1)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[1].type)" v-model.number="leaderboardReward.content[1].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[1].type === 3" v-model.number="leaderboardReward.content[1].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[1].type === 2" v-model="leaderboardReward.content[1].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[1].type === 6" v-model.number="leaderboardReward.content[1].emoji_index" min="1" placeholder="Emoji Index" />
            <input v-if="leaderboardReward.content[1].type === 14" v-model.number="leaderboardReward.content[1].avatar_index" min="1" placeholder="Avatar Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[2].type" @change="() => updateLeaderboardRewardBody(index, 2)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[2].type)" v-model.number="leaderboardReward.content[2].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[2].type === 3" v-model.number="leaderboardReward.content[2].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[2].type === 2" v-model="leaderboardReward.content[2].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[2].type === 6" v-model.number="leaderboardReward.content[2].emoji_index" min="1" placeholder="Emoji Index" />
            <input v-if="leaderboardReward.content[2].type === 14" v-model.number="leaderboardReward.content[2].avatar_index" min="1" placeholder="Avatar Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[3].type" @change="() => updateLeaderboardRewardBody(index, 3)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[3].type)" v-model.number="leaderboardReward.content[3].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[3].type === 3" v-model.number="leaderboardReward.content[3].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[3].type === 2" v-model="leaderboardReward.content[3].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[3].type === 6" v-model.number="leaderboardReward.content[3].emoji_index" min="1" placeholder="Emoji Index" />
            <input v-if="leaderboardReward.content[3].type === 14" v-model.number="leaderboardReward.content[3].avatar_index" min="1" placeholder="Avatar Index" />
          </div>

        </div>
        <div class="flex margin">
            <Button type="error" :onClick="() => removeLastLeaderboardReward()" text="Remove Reward" :loading="false" size="md" />
            <Button type="success" :onClick="() => addLeaderboardReward()" text="Add Reward" :loading="false" size="md" />
        </div>
      </div>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>

  </div>
</template>

<script>
import { computed, reactive, onMounted, watch, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import dispatchMap from "@/constants/dispatchMap";

import eventConstants from "@/constants/eventConstants";
import unitConstants from "@/constants/unitConstants";
import languageConstants from "@/constants/languagesTemp";
import eventSchema from "@/schemas/event"
import 'v-calendar/dist/style.css';
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
import Button from '../../components/common/Button.vue';
import changeDetector from '@/util/changeDetector'
import PushNoificationSchedule from '../../components/PushNoificationSchedule.vue';


export default {
  name: "Event",
  components: {
    ConfirmPopup,
    Button,
    PushNoificationSchedule
  },
  setup() {
    const store = useStore();
    const usedPages = ["events"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;
    const popupOpen = ref(false);
    const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
    const popupText = ref(popupTexts[0])
    const popupType = ref("refresh");
    const changes = ref([]);
    const router = useRouter();
    window.firstEvent = null;
    onBeforeMount(() => usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    }));
    const events = computed(() => store.getters['events/getEvents'])
    const chests = computed(() => store.getters["chests/getChests"])
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })
    const assetOptions = computed(() => {
      let assetArray = []
      assetList.value.forEach(asset => {
        if(asset.doc_ref === undefined){
          assetArray.push("DEFAULT BANNER")
        }
        else{
          assetArray.push(asset.doc_ref)
        }
      })
      return assetArray
    });
    const currentEvent = reactive({ ...eventSchema.getSchema() })
    const route = useRoute();

    onMounted(() => {
      if(route.query.id && events.value.length > 0) {
        const editingEvent = JSON.parse(JSON.stringify(events.value.find(x => x.id === route.query.id)))
        Object.keys(editingEvent).forEach(key => {
          currentEvent[key] = editingEvent[key]
        })
        window.firstEvent = JSON.parse(JSON.stringify(currentEvent));
      }
      store.dispatch("loader/loadingStatus", false)
    })
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(() => store.getters['events/getEvents'], events => {
      if(route.query.id && events.length > 0) {
        const editingEvent = JSON.parse(JSON.stringify(events.find(x => x.id === route.query.id)))
        Object.keys(editingEvent).forEach(key => {
          currentEvent[key] = editingEvent[key]
        })
        window.firstEvent = JSON.parse(JSON.stringify(currentEvent));
      }
    })

    const submit = () => {
      if(route.query.id) {
        store.dispatch('events/updateEvent', { id: route.query.id, updateBody: currentEvent }).then(() => {
          socket.emit("update", {
              socketId: socket.id,
              itemId: currentEvent.id,
              page: "Event Challenge"
            });
        })
      } else {
        store.dispatch('events/addEvent', currentEvent).then(() => {
          socket.emit("add", {
              socketId: socket.id,
              itemId: "Event Challenge",
              page: "Event Challenge",
              data: currentEvent
            });
        })
      }
      router.push({ name: "EventChallengeList"});
    }

    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.itemId === currentEvent.id && data.page === "Event Challenge" && data.data){
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        changes.value = changeDetector.detect(currentEvent, data.data, "eventChallenge")
      }
    })

    const updateRewardBody = index => {
      currentEvent.rewards[index][0] = {
        type: currentEvent.rewards[index][0].type,
      }
      if(currentEvent.rewards[index][0].type === 2) {
        currentEvent.rewards[index][0].id = ""
        return
      }
      currentEvent.rewards[index][0].amout = 0;
      if(currentEvent.rewards[index][0].type === 3)
        currentEvent.rewards[index][0].rarity = 0
    }

    const addReward = () => {
      currentEvent.rewards[Object.keys(currentEvent.rewards).length + 1 + ""] = [
        {
          type: 0,
          amount: 0
        }
      ]
    }

    const removeLastReward = () => {
      delete currentEvent.rewards[Object.keys(currentEvent.rewards).length + ""]
    }

    const addLeaderboardReward = () => {
      currentEvent.leaderboard_rewards.push({
        rewardCount: 1,
        content: [
          {
            type: 0,
            amount: 0
          },
          {
            type: 1,
            amount: 0
          }
        ]
      })
    }

    const removeLastLeaderboardReward = () => {
      currentEvent.leaderboard_rewards.splice(currentEvent.leaderboard_rewards.length - 1, 1)
    }

    const updateLeaderboardRewardBody = (index, rewardIndex) => {
      currentEvent.leaderboard_rewards[index].content[rewardIndex] = {
        type: currentEvent.leaderboard_rewards[index].content[rewardIndex].type,
      }
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex].type === 2) {
        currentEvent.leaderboard_rewards[index].content[rewardIndex].chest_id = ""
        currentEvent.leaderboard_rewards[index].content[rewardIndex].amount = 1

        return
      }
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex]) {
        currentEvent.leaderboard_rewards[index].content[rewardIndex].emoji_index = 0;
        return
      }
      currentEvent.leaderboard_rewards[index].content[rewardIndex].amout = 0;
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex].type === 3)
        currentEvent.leaderboard_rewards[index].content[rewardIndex].rarity = 0
    }

    const addInfoField = () => {
      currentEvent.info_fields.push({ icon:0, name: {}, text: {} })
    }

    const removeInfoField = (index) => {
      currentEvent.info_fields.splice(index, 1)
    }

    const getDefaultInfoFields = () => {
      currentEvent.info_fields = eventSchema.getDefaultInfoFields(currentEvent.modifier_type)
      const localizables = eventSchema.getDefaultLocalizables(currentEvent.modifier_type)
      if (localizables) {
        currentEvent.localizables = localizables;
        currentEvent.name = localizables.name['en']
        currentEvent.short_description = localizables.short_description['en']
      }
    }

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      changes.value = changeDetector.detect(window.firstEvent, currentEvent, "eventChallenge");
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      chests,
      eventConstants,
      unitConstants,
      currentEvent,
      submit,
      updateRewardBody,
      updateLeaderboardRewardBody,
      addReward,
      removeLastReward,
      addLeaderboardReward,
      removeLastLeaderboardReward,
      env,
      assetList,
      assetOptions,
      popupOpen,
      popupText,
      popupType,
      selectedLanguage,
      languages,
      addInfoField,
      removeInfoField,
      getDefaultInfoFields,
      changes,
      openConfirm
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
}

.leaderboard-container {
  display: inline-flex;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  height: auto;

}

.leaderboard-container span {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

.leaderboard-cell.alternate {
  background-color: lightgrey;
}
.leaderboard-cell {
  margin: 10px 0;
}



.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.reward-cell * {
  margin: 5px 10px;
}



.reward-cell {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
label.field-info {
  font-size: 0.7rem;
}

.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.margin{
  margin: 10px;
}

</style>